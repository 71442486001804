import React from 'react'
import connect from 'react-redux/es/connect/connect'
import {withStyles} from '@material-ui/core'
import AccountField from './AccountField'
import styles from '../style'
import Button from '@material-ui/core/Button'

import {
    adminConnectSkywardAccount,
    adminRemoveSkywardAccount
  } from 'redux/admin/Actions'
  

class SkywardAccountInfo extends React.Component {
    render() {
        const {skywardId, skywardFullName, adminConnectSkywardAccount, adminRemoveSkywardAccount, classes } = this.props

        return (
            <div>
                <h2>
                    Skyward
                    {skywardId == null ?
                        <Button className={classes.button} onClick={adminConnectSkywardAccount}  
                            color="primary" variant='outlined'>
                            Enable login with Skyward       
                        </Button>
                        :
                        <Button className={classes.button} onClick={adminRemoveSkywardAccount}  
                            color="secondary" variant='outlined'>
                            Disable login with Skyward     
                        </Button>   
                    }
                </h2>
                {skywardId == null ?
                    null 
                    :
                    <AccountField fieldName="Account name" fieldValue={skywardFullName} />    
                }     
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {...state.admin.skywardInfo }
} 

const mapDispatchToProps = {
    adminConnectSkywardAccount,
    adminRemoveSkywardAccount
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(SkywardAccountInfo))
