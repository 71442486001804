import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import store from './redux/Store';
import Kiosk from './apps/kiosk';
import { SingletonKioskConnected } from './apps/singleton-kiosk';
import Admin from './apps/admin';
import Reset from './apps/reset';
import Install from './apps/install';
import { MuiThemeProvider } from '@material-ui/core/styles';
import PrintProvider, { NoPrint } from 'react-easy-print';
import CssBaseline from '@material-ui/core/CssBaseline';
import SnackMessage from 'common/components/SnackMessage';
import { URL_RESET_PASSWORD } from './common/kioskConstants';
import * as Sentry from '@sentry/react';
import {showMaintenancePage} from 'redux/actions'

const mapStateToProps = (state) => ({
  isMaintenance: state.common.maintenance.isEnabled,
  maintenanceContent: state.common.maintenance.pageContent,
  theme: state.theme,
});

const resetPasswordPath = `/${URL_RESET_PASSWORD}`;

class MuiThemeClass extends React.Component {
  render() {
    const { children, theme } = this.props;
    return (
      <div>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </div>
    );
  }
}

const MuiTheme = connect(mapStateToProps)(MuiThemeClass);


class App extends Component {

  componentDidMount() {
    if (this.props.isMaintenance) {
      this.startPollingServerHealth();
    }
  }

  render() {
    const { isMaintenance, maintenanceContent } = this.props;

    if (isMaintenance) {
      return <div dangerouslySetInnerHTML={{ __html: maintenanceContent }} />;
    }

    return (
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <MuiTheme>
            <SnackMessage />
            <CssBaseline />
            <PrintProvider>
              <NoPrint>
                <Router>
                  <Switch>
                    <Route exact path="/" component={Kiosk} />
                    <Route exact path="/virtualkiosk" component={SingletonKioskConnected} />
                    <Route path="/mgmt" component={Admin} />
                    <Route exact path={resetPasswordPath} component={Reset} />
                    <Route path="/instellerizer" component={Install} />
                  </Switch>
                </Router>
              </NoPrint>
            </PrintProvider>
          </MuiTheme>
        </Provider>
      </Sentry.ErrorBoundary>
    );
  }
}

const mapDispatchToProps = {
  showMaintenancePage
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
