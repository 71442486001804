import { all, call, put, takeEvery, cancelled, take, fork, cancel } from 'redux-saga/effects';
import { watches as installWatches } from "redux/install/Saga"
import { kioskWatches } from "redux/kiosk/KioskSaga"
import { adminWatches } from "redux/admin/Saga"
import {
  get,
  post,
  changePageToHtml,
  uiDecoration,
  withErrorHandling
} from "redux/utils"

import {
  LOGIN_WITH_SKYWARD,
  START_POLLING_SERVER_HEALTH,
  STOP_POLLING_SERVER_HEALTH,
  CANCEL_SERVER_HEALTH_POLLING,
  stopPollingServerHealth,
  cancelServerHealthPolling,
  disableMaintenanceMode
} from 'redux/actions'

import {
  URL_API_ROOT
} from "redux/constants"

import {delay} from "redux-saga"

const payloadOnly = (fn) => (action) => fn(action.payload)

export function* loginWithSkyward({redirectUrl, parameters}) {
  const obj = yield post(`${URL_API_ROOT}/skywardAccount/redirectToLogin`, {
    returnClientAddress: redirectUrl,
    ...parameters
  });
  if(yield uiDecoration(obj)){
    changePageToHtml(obj.data)
  }  
}

export function* startPollingServerHealth() {
  const bgPollTask = yield fork(bgPoll, getServerHealh, 5000);
  yield take(STOP_POLLING_SERVER_HEALTH);
  yield cancel(bgPollTask);
}

export function* cancelServerHealthPollingSaga() {
  yield put(stopPollingServerHealth());
  window.location.reload();
}

export function* getServerHealh() {
  try {
    const obj = yield get(`${URL_API_ROOT}/health`)
    if(yield uiDecoration(obj, false)){
      yield put(disableMaintenanceMode());
      yield put(cancelServerHealthPolling());
    }
  } catch (error) {
    console.error('An error occurred in getServerHealh:', error);
  }
}

export function* bgPoll(pollingFn, delayMS=5000) {
  try {
    while (true) {
      yield call(pollingFn)
      yield delay(delayMS)
    }
  } finally {
    yield cancelled()
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    ...installWatches,
    ...kioskWatches,
    ...adminWatches,
    ...[
      takeEvery(LOGIN_WITH_SKYWARD, payloadOnly(withErrorHandling(loginWithSkyward))),
      takeEvery(START_POLLING_SERVER_HEALTH, startPollingServerHealth),
      takeEvery(CANCEL_SERVER_HEALTH_POLLING, payloadOnly(cancelServerHealthPollingSaga)),
    ]
  ])  
}