/* global window */
import {put, takeEvery, takeLatest, select, call, cancelled, take, fork, all, cancel} from 'redux-saga/effects'
import queryString from 'query-string'
import axios from 'axios'
import moment from 'moment-timezone'
import Student from 'common/model/Student'

import {
  URL_ACCOUNT_SETTINGS
} from '../../redux/constants'

import {
  ADMIN_BOOT,
  adminIsReady,
  adminSetConfiguration,
} from 'redux/actions'

import {
  snackMessageHide,
  snackMessageInfo,
  snackMessageShow,
  snackMessageError,
  snackMessageSuccess,
  snackMessageWarning,
  bootPruneAppStateExcept
} from 'redux/RootActions'

import {
  ADMIN_APP_SETTINGS_FETCH,
  ADMIN_APP_SETTINGS_SAVE,
  ADMIN_AT_RECORD_EDIT_SAVE,
  ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID,
  ADMIN_DELETE_KIOSK,
  ADMIN_EMAIL_TEST,
  ADMIN_FETCH_RICH_LOCATION_BY_ID,
  ADMIN_LOGIN_SEND,
  ADMIN_MESSAGING_EDIT_SAVE_CONTENT,
  ADMIN_SAVE_KIOSK_SETTINGS,
  ADMIN_SAVE_LOCATION_SETTINGS,
  ADMIN_SAVE_STUDENT,
  ADMIN_COMING_SOON,
  ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT,
  ADMIN_USER_ACCOUNT_DELETE,
  ADMIN_USER_ACCOUNT_EDIT,
  ADMIN_USER_ACCOUNT_EDIT_SAVE,
  ADMIN_USER_ACCOUNT_SEARCH,
  ADMIN_BELL_RULES_EDIT_SAVE,
  ADMIN_FETCH_RICH_STUDENT_BY_ID,
  ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_SAVE,
  ADMIN_SEARCH_STUDENTS,
  ADMIN_SEARCH_LOCATOR_STUDENTS,
  ADMIN_SELECT_LOCATOR_STUDENT,
  ADMIN_SET_LOCATOR_SCHEDULE_PICKER_DATE,
  ADMIN_USER_ACCOUNT_OPEN_UPLOADER,
  ADMIN_USER_ACCOUNT_UPLOAD,
  ADMIN_COURSES_FETCH,
  ADMIN_AT_MONITOR_COURSES_SAVE,
  ADMIN_WORKER_JOB_EDIT,
  ADMIN_LOCATIONS_FETCH,
  ADMIN_AT_MONITOR_LOCATIONS_SAVE,
  ADMIN_SCHOOL_YEARS_FETCH,
  ADMIN_START_SCHOOL_YEARS_STATE_POLLING,
  ADMIN_STOP_SCHOOL_YEARS_STATE_POLLING,
  ADMIN_MIGRATE_SCHOOL_YEAR,
  ADMIN_START_SYNC_SCHOOL_YEARS,
  ADMIN_OVERRIDABLE_AT_TYPES_SAVE,
  ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID,
  ADMIN_SEMESTER_RULE_EDIT_SAVE, 
  MESSAGING_EDIT_PREVIEW_CONTENT,
  SEARCH_FROM_URL,
  ADMIN_FORGOT_PASSWORD_SEND,
  STOP_HOME_PAGE_POLL,
  ADMIN_SEARCH_LOCATIONS_BY,
  ADMIN_WORKER_JOB_EDIT_SAVE,
  ADMIN_WORKER_JOB_START_STOP,
  SET_URL_SEARCH_PROPERTY,
  AT_RECORD_DELETE,
  START_HOME_PAGE_DATA_POLL,
  REPORT_TYPES_FETCH,
  REPORT_PAGE_FETCH_REPORT_BY_ID,
  WORKER_JOB_FETCH_LOGS,

  ADMIN_KIOSK_DEFAULT_CONFIGURATION_FORCE_PUSH,
  ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_AND_SAVE,
  ADMIN_LOGOUT_SEND,
  ADMIN_START_ALL_WORKER_JOBS_POLL,
  ADMIN_STOP_ALL_WORKER_JOBS_POLL,
  FETCH_STUDENT_PAGE_SCHEDULE_ATTENDANCE,
  FETCH_STUDENT_PAGE_LIST_ATTENDANCE,
  SET_STUDENT_DETAIL_PAGE_ATTENDANCE_VIEW,
  FETCH_SCHEDULE_DISPLAY_SETTINGS,
  SAVE_SCHEDULE_DISPLAY_SETTINGS,
  ENTITY_CHOOSER_VALIDATE_SET,
  ENTITY_CHOOSER_OPEN,
  BUILDING_CHOOSER_VALIDATE_SET,
  BUILDING_CHOOSER_OPEN,
  BUILDING_CHOOSER_SET_IS_ENABLED,
  BUILDING_CHOOSER_CONFIRM,
  DANGEROUS_PIRATE_ACTION_GENERATE_POSITIVE_ATTENDANCE,
  DANGEROUS_PIRATE_ACTION_DELETE_POSITIVE_ATTENDANCE_FROM_DB,
  DANGEROUS_PIRATE_ACTION_DELETE_ALL_ATTENDANCE_FROM_DB,
  PIRATE_GENERATE_NEGATIVE_ATTENDANCE,
  PIRATE_ADD_KIOSKS_TO_ALL_LOCATIONS,
  PIRATE_DELETE_NEGATIVE_ATTENDANCE_FROM_DB,
  PIRATE_MARK_NEGATIVE_ATTENDANCE_FOR_DELETION_FROM_Q,

  SINGLETON_KIOSK_EDIT_FETCH,
  PPK_CONFIGURATION_EDIT_SAVE,
  PPK_EDIT,
  STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG,

  ADMIN_CONNECT_SKYWARD_ACCOUNT,
  ADMIN_REMOVE_SKYWARD_ACCOUNT,

  appSettingsFetchResponse,
  appWorkerJobFetchResponse,
  appSettingsSaveResponse,
  atRecordEditDone,

  bellRulesEditDone,
  bellRulesEditSaveResponse,
  bellScheduleFetchByFaciltyIdResponse,

  closeKioskEditor,
  fetchRichLocationById,
  fetchHomePageDataResponse,
  kioskDefaultConfigurationEditResponse,
  loginShowUI,

  messagingFetchResponse,
  messagingEditSaveContentResponse,

  reportPageFetchReportByIdResponse,
  reportTypesFetchResponse,

  saveKioskSettingsResponse,
  saveLocationSettingsResponse,
  searchStudentsResponse,
  resetSearchLocatorStudentsResponse,
  setStudentLocatorInfo,
  searchLocatorStudentsResponse,
  setLocatorSchedulePeriods,
  setSyncQueuesResponse,
  setLocationPageInitialData,
  setStudentPageInitialData,
  searchLocationsBy,
  userAccountCloseUploader,
  workerJobEditSaveResponse,
  workerJobEditDone,
  messagingEditPreviewContentResponse,
  workerJobViewLogs,
  entityChooserOpenResponse,
  entityChooserSetIsEnabled,
  entitySetIsEnabledResponse,
  buildingChooserSetIsEnabled,
  buildingChooserOpenResponse,
  buildingChooseConfirmResponse,
  locationsSyncWaitResponse,
  dangerousUpdateGeneralProperty,
  pirateUpdateGeneralProperty,
  ppkConfigurationEditResponse,
  setStudentPageScheduleAttendance,

  userAccountEditDone,
  userAccountEditSaveResponse,
  userAccountSearchResponse,
  setStudentPageListAttendance,
  setScheduleDisplaySettings,

  ppkEditClose,
  studentDetailPageReportFetchAttendanceLogResponse,
  singletonKioskEditFetchResponse,
  SEND_ENTITY_IS_ENABLED,
  hideAddEntitiesDialog,

  coursesFetch,
  coursesFetchResponse,
  locationsFetch,
  locationsFetchResponse,
  attendanceMonitorCoursesSaveResponse,
  attendanceMonitorCoursesEditDone,
  attendanceMonitorLocationsEditDone,
  attendanceMonitorLocationsSaveResponse,
  schoolYearsFetchResponse,
  stopSyncSchoolYearsStatePolling,
  startSyncSchoolYearsStatePolling,
  migrateSchoolYearResponse,
  migrateSchoolYearError,
  overridableAtTypesSaveResponse,
  overridableAtTypesEditDone,
  semesterRuleFetchByFaciltyIdResponse,
  semesterRuleEditDone,
  semesterRuleEditSaveResponse,
  adminRemoveSkywardState,
} from 'redux/admin/Actions'

import {
  del,
  get,
  getStatePath,
  post,
  filter, uiSnackMessageOnResponse, findOneBy, computePPKKey, determineFailedLoginMessage, changePageToHtml, showBootMessageIfAny,
  withErrorHandling
} from "redux/utils"

import {
  AT_RECORD_CREATED_WITH_Q,
  ATTENDANCE_VIEW_LIST, ATTENDANCE_VIEW_WEEK,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER, FORMAT_AMPM, FORMAT_YMD,
  ID_ZERO,
  KDCL_LOCATION,
  KIOSK_TYPE_PPK,
  SEARCH_CONTEXT_AT_USER_LIST_PAGE,
  SEARCH_CONTEXT_LOCATIONS_PAGE,
  SEARCH_CONTEXT_MESSAGING_PAGE,
  SEARCH_CONTEXT_USER_ACCOUNT_PAGE,
  STATUS_MESSAGE_TYPE_ERROR,
  STATUS_MESSAGE_TYPE_INFO,
  URL_API_ROOT,
  URL_API_ADMIN_ROOT,
  URL_BELL_SCHEDULE,
  URL_SEMESTER_RULES,
  WORKER_JOB_ACTION_START,
  SYNC_RUN_STATUS_WAITING
} from "redux/constants"

import {delay} from "redux-saga"
import {ADMIN_SET_CURRENT_PATH} from "redux/actions"
import { ADMIN_REQUEST_SYNC_QUEUES } from './Actions'

function getState(path) {
  return select(getStatePath, path)
}

const payloadOnly = (fn) => (action) => fn(action.payload)

let cachedFacilityId = 0

/**
 * Handles showing success and error messages based on response.success value.
 * @param response
 * @param showSuccess - defaults to true. If a string it is used if response.data.userMessage is not available
 * @param forceMessage - forces user of showSuccess string
 * @returns {boolean}
 */
const uiDecoration = uiSnackMessageOnResponse(snackMessageShow, getState)

const formatReportListProperties = (reportList) => {
  if(reportList.constructor === Array) {
    if(reportList.length > 0 && reportList[0].time) {
      for(let i = 0; i < reportList.length; i++) {
        reportList[i].time = moment.unix(reportList[i].time).format(FORMAT_AMPM)
      }
    }
  }
  return reportList
}


function* nextActionOnError(obj, defaultMessage='There was an error communicating with the server. Please reload the page') {
  switch(obj.nextAction) {
    case 'showLogin' :
      yield put(loginShowUI(obj.payload))
      return obj
    default :
  }
}

/**
 * Calls the dispatch function with response data as the payload IF response.success is true
 * @param response
 * @param dispatch
 * @param showSuccess
 * @param forceMessage
 * @returns {IterableIterator<*>}
 */
function* dispatchWithUI(response, dispatch, showSuccess=true) {
  if(yield uiDecoration(response, showSuccess)) {
    if(response.hasOwnProperty('data')) {
      yield put(dispatch(response.data))
    }
    else if(response.hasOwnProperty('payload')) {
      yield put(dispatch(response.payload))
    }
    else {
      console.error("dispatchWithUI cannot dispatch. Response does not conform to expected structure {success, data} or {success, response}")
    }

  }
  return response.success
}

function* startAdmin(obj) {
  yield uiDecoration(obj, false)
  console.log('serverTime', obj.payload.serverTime)
  yield put(adminSetConfiguration(obj.payload))
  yield put(adminIsReady())
}

export function* adminBoot() {
  yield put(bootPruneAppStateExcept('admin'))
  const obj = yield axios.get(`${URL_API_ADMIN_ROOT}/boot`)
 
  yield showBootMessageIfAny() 

  if(yield uiDecoration(obj, false)) {
    if(obj.nextAction === 'start') {
      yield startAdmin(obj)
    } else if(obj.nextAction === 'showLogin') {
      yield put(dangerousUpdateGeneralProperty('organizationName', obj.payload.organizationName))
      yield put(loginShowUI(obj.payload))
    } else {
      console.log(`admin adminBoot - nextAction unknown:${obj.nextAction}`, obj)
      window.location.reload()
    }
  }
}

export function* fetchKiosksByLocation(locationId) {
  const obj = yield get(`${URL_API_ADMIN_ROOT}/location/${locationId}`, {
    withKiosks:true,
    withKioskDefaultConfiguration:true
  })
  if(yield uiDecoration(obj, false)) {
    yield put(setLocationPageInitialData(obj.payload))
  }
}

export function* fetchRichStudentById({atUserId, dateInWeek=false}, keep=false) {
  let pageSize = yield getState('admin.studentDetailPage.listView.pageSize'),
    ymdStart = false

  if(dateInWeek) {
    ymdStart = getWeekMoments(dateInWeek).start
  }

  if(!keep) {
    yield put(setStudentPageInitialData({
      student: Student({}),
      weekCalendar:{
        ymdStart
      },
      listView:{
        pageNum:0,
        totalCount:0,
        pageSize,
        atRecordsByDate:[]
      },
      attendanceReport:{
        report:false,
        jsbCustomConfiguration: false,
        firstDateYMD: moment().format(FORMAT_YMD),
        lastDateYMD: moment().format(FORMAT_YMD),
        reportTypeId:ID_ZERO,
      }
    }, ))
  }

  yield put(snackMessageInfo('Getting student information', DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  const obj = yield get(`${URL_API_ADMIN_ROOT}/at_user/${atUserId}/rich`)
  yield put(snackMessageHide())
  if(yield uiDecoration(obj)) {
    yield put(setStudentPageInitialData({student:Student(obj.payload)}))
  } else {
    return
  }

  const studentDetailPage = yield getState('admin.studentDetailPage')

  switch(studentDetailPage.attendanceViewTabIndex) {
    case ATTENDANCE_VIEW_LIST :
      yield fetchStudentPageListAttendance({
        atUserId,
        pageNum: studentDetailPage.listView.pageNum,
        pageSize: studentDetailPage.listView.pageSize
      })
      break
    case ATTENDANCE_VIEW_WEEK :
    default :
      yield fetchStudentPageScheduleAttendance({
        atUserId,
        dateInWeek: studentDetailPage.weekCalendar.ymdStart
      })
      break
  }
}

export function* fetchStudentPageListAttendance({atUserId, pageNum, pageSize, atTypeId='all', isReporting='all'}) {
  const params = {pageNum, pageSize}
  if(atTypeId !== 'all') {
    params.atTypeId = parseInt(atTypeId)
  }
  if(isReporting !== 'all') {
    params.isReporting = !!isReporting
  }

  const resp = yield get( `${URL_API_ADMIN_ROOT}/at_user/${atUserId}/at_records`, params)

  if(yield uiDecoration(resp)) { //pageNum and pageSize are returned by the server
    resp.payload.atTypeId = atTypeId
    resp.payload.isReporting = isReporting
    yield put(setStudentPageListAttendance(resp.payload))
  }
}

/**
 * Returns three Moment instances representing the first and last seconds of the week and the first second of the passed in dateInWeek
 * @param dateInWeek
 * @returns {{current: *, start: *, end: *}}
 */
const getWeekMoments = (dateInWeek=false) => {
  const seedDate = (dateInWeek? moment(dateInWeek) : moment()).set('hours', 0).set('minutes', 0).set('seconds', 0)
  const end = moment(seedDate).set('hours', 23).set('minutes', 59).set('seconds', 59).add(6 - seedDate.day(), 'day')
  const start = moment(end.format(FORMAT_YMD)).subtract(6, 'days')
  return {
    seedDate,
    end,
    start
  }
}

export function* singletonKioskEditFetch() {
  const { singletonKioskId } = yield getState('admin.ids')
  yield editKioskFetch(singletonKioskId)
}
export function* editKioskFetch(kioskId) {
  const resp = yield get( `${URL_API_ADMIN_ROOT}/kiosk/${kioskId}`)

  if(yield uiDecoration(resp, false)) {
    yield put(singletonKioskEditFetchResponse(resp.payload))
  }
}

export function* fetchStudentPageScheduleAttendance({atUserId, dateInWeek}) {
  const {seedDate:currentDate, end:endsOnBefore, start:startsOnAfter} = getWeekMoments(dateInWeek)

  const sched = yield get( `${URL_API_ADMIN_ROOT}/at_user/${atUserId}/schedule`, {
    startsOnAfter: startsOnAfter.format(FORMAT_YMD),
    endsOnBefore: endsOnBefore.format(FORMAT_YMD)
  })

  if(yield uiDecoration(sched)) {
    sched.payload.currentYMD = currentDate.format(FORMAT_YMD)
    yield put(setStudentPageScheduleAttendance(sched.payload))
  }
}

export function* fetchStudentLocatorScheduleAttendance() {
  const { selectedStudent } = yield getState('admin.locatorStudentPicker')
  if(Object.keys(selectedStudent).length === 0){
    return
  }
  const { pickerDate } = yield getState('admin.locatorSchedule')
  const uDate = moment(pickerDate).unix()
  const periodsResult = yield get( `${URL_API_ADMIN_ROOT}/at_user/${selectedStudent.id}/locatorSchedule`, {
    date: uDate
  })

  yield put(setLocatorSchedulePeriods(periodsResult.payload))
}

export function* fetchSchDispSettings() {
  const schedDisplaySettings = yield get( `${URL_API_ADMIN_ROOT}/user/schedule_display_settings`);
  if (yield uiDecoration(schedDisplaySettings)) {
    yield put(setScheduleDisplaySettings(schedDisplaySettings.payload))
  }
}

export function* saveSchDispSettings(action) {
  const schedDisplaySettings = yield post( `${URL_API_ADMIN_ROOT}/user/schedule_display_settings`, action);
  if (yield uiDecoration(schedDisplaySettings)) {
    yield put(setScheduleDisplaySettings(action));
  }
}

export function *setStudentDetailPageAttendanceView({attendanceView, dateInWeek=false}) {
  const studentDetailPage = yield getState('admin.studentDetailPage')
  const atUserId = studentDetailPage.student.id

  switch(attendanceView) {
    case ATTENDANCE_VIEW_LIST :
      yield fetchStudentPageListAttendance({
        atUserId,
        pageNum: studentDetailPage.listView.pageNum,
        pageSize: studentDetailPage.listView.pageSize
      })
      break
    case ATTENDANCE_VIEW_WEEK :
    default :
      yield fetchStudentPageScheduleAttendance({
        atUserId,
        dateInWeek
      })
      break
  }
}

export function* saveStudent(action) {
  const student = action.payload
    const obj = yield post(`${URL_API_ADMIN_ROOT}/at_user/${student.id}/rich`, student)
  if(yield uiDecoration(obj)) {
    yield put(setStudentPageInitialData({student:obj.payload}))
  }
}

export function* workerJobFetchLogs(payload) {
  const obj = yield get(`${URL_API_ADMIN_ROOT}/worker_job/${payload}/logs`)
  yield dispatchWithUI(obj, workerJobViewLogs)

}

export function* workerJobEditSave(payload) {
  let workerJob = payload
  if(!workerJob) workerJob = yield getState('admin.dialogs.workerJob')

  const obj = yield post(`${URL_API_ADMIN_ROOT}/worker_job/${workerJob.id}`, workerJob)
  if(yield dispatchWithUI(obj, workerJobEditSaveResponse)) {
    yield put(workerJobEditDone(obj.payload))
  }
}

export function* workerJobStartStop(payload) {
  const action = payload.action === WORKER_JOB_ACTION_START? 'start' : 'stop'
  const obj = yield post(`${URL_API_ADMIN_ROOT}/worker_job/${payload.id}/${action}`)
  yield dispatchWithUI(obj, workerJobEditSaveResponse)
}

export function* saveAtRecord() {
  const atRecord = yield getState('admin.dialogs.atRecordEditor.atRecord')
  if(atRecord.createdWith === AT_RECORD_CREATED_WITH_Q && atRecord.isReportingAtRecord) {
    atRecord.id = false
  }
  const idFrag = atRecord.id? `/${atRecord.id}` : ''
  const obj = yield post(`${URL_API_ADMIN_ROOT}/at_record${idFrag}`, atRecord)

  if(yield dispatchWithUI(obj, atRecordEditDone)) {
    const weekCalendar = yield getState('admin.studentDetailPage.weekCalendar')
    const dateInWeek = weekCalendar? weekCalendar.ymdStart : null
    yield fetchRichStudentById({atUserId: atRecord.atUserId, dateInWeek}, true)
  }
}

export function* deleteAtRecord() {
  const atRecord = yield getState('admin.dialogs.atRecordEditor.atRecord')

  if(!atRecord.id) return

  const obj = yield del(`${URL_API_ADMIN_ROOT}/at_record/${atRecord.id}`)

  if(yield dispatchWithUI(obj, atRecordEditDone)) {
    const weekCalendar = yield getState('admin.studentDetailPage.weekCalendar')
    const dateInWeek = weekCalendar? weekCalendar.ymdStart : null
    yield fetchRichStudentById({atUserId: atRecord.atUserId, dateInWeek}, true)
  }
}

export function* fetchAppSettings() {
  yield all([
    call(fetchAllWorkerJobs),
    call(fetchSettings),
    ]
  )
}

export function* fetchSettings() {
  const obj = yield get(`${URL_API_ADMIN_ROOT}/settings`)
  if(yield dispatchWithUI(obj, appSettingsFetchResponse, false )) {
  } else {
    console.warn("Error getting app settings ", obj)
  }
}

export function* fetchAllWorkerJobs() {
  const obj = yield get(`${URL_API_ADMIN_ROOT}/worker_jobs`)
  if(yield dispatchWithUI(obj, appWorkerJobFetchResponse, false ) ) {
  } else {
    console.warn("Error getting app settings ", obj)
  }
}
export function* fetchHomePageData() {
  const state = yield getState('admin')
  const facilityIdFrag = (state.currentFacilityId)? `/${state.currentFacilityId}` : ''
  let obj
  if(state.currentItemId !== '') {
    obj = yield get(`${URL_API_ADMIN_ROOT}/report/week_summary${facilityIdFrag}`, {
      dateYMD: state.currentItemId
    })
  } else {
    obj = yield get(`${URL_API_ADMIN_ROOT}/report/week_summary${facilityIdFrag}`)
  }
  if(yield dispatchWithUI(obj, fetchHomePageDataResponse, false ) ) {
  } else {
    console.warn("Error getting home page data ", obj)
  }
}

export function* saveAppSettings () {
  const settings = yield getState('admin.settings')
  if(window.confirm("Changing these settings is a big deal. Are you sure?")) {
    const obj = yield post(
      `${URL_API_ADMIN_ROOT}/settings`,
      {settings}
    )
    yield dispatchWithUI(obj, appSettingsSaveResponse)
  }
}

export function* deleteKiosk (action) {
  const state = yield getState('admin')
  const obj = yield del(`${URL_API_ADMIN_ROOT}/kiosk/${action.payload}`)
  if(yield uiDecoration(obj)) {
    if(state.currentPath.endsWith(`location/${state.currentItemId}`)) {
      yield put(fetchRichLocationById(state.currentItemId))
      yield put(closeKioskEditor())
    }
  }
}

export function* saveKioskSettings () {
  const kioskConfigurationEditor = yield getState('admin.dialogs.kioskConfigurationEditor')
  const kiosk = kioskConfigurationEditor.kiosk
  if(!kiosk) {
    console.error(`Unable to save as UI is probably in an incorrect state! kioskConfigurationEditor should have .kiosk:`, JSON.stringify(kioskConfigurationEditor, null, 2) )
    return
  }
  const obj = yield post(
    `${URL_API_ADMIN_ROOT}/kiosk/${kiosk.id}`,
    {kiosk}
  )
  if(yield uiDecoration(obj)) {
    yield put(saveKioskSettingsResponse(obj.payload))
    yield put(closeKioskEditor())
  }
}


export function* saveLocationSettings () {
  const kioskConfigurationEditor = yield getState('admin.dialogs.kioskConfigurationEditor')
  if(kioskConfigurationEditor.kiosk) {
    console.error(`Unable to save as UI is probably in an incorrect state. Kiosk should not be set, but it is!`, JSON.stringify(kioskConfigurationEditor.kiosk, null, 2) )
    return
  }
  const obj = yield post(
    `${URL_API_ADMIN_ROOT}/location/${kioskConfigurationEditor.location.id}/kiosk_default_configuration`,
    {kioskDefaultConfiguration: kioskConfigurationEditor.kioskDefaultConfiguration}
    )
  if(yield uiDecoration(obj)) {
    yield put(saveLocationSettingsResponse(obj.data))
    yield put(closeKioskEditor())
  }
}

export function* searchStudents() {
  const {searchString, searchBy} = yield getState('admin.studentSearchPage')
  const currentFacilityId = yield getState('admin.currentFacilityId')
  const apiEndpoint = (currentFacilityId !== '')? `${URL_API_ADMIN_ROOT}/facility/${currentFacilityId}/at_user` : `${URL_API_ADMIN_ROOT}/at_user`
  const obj = yield get(
    apiEndpoint,
    {searchString, searchBy}
  )
  if(yield uiDecoration(obj, false))
    yield put(searchStudentsResponse(obj.payload))
  else yield nextActionOnError(obj)
}

export function* searchLocatorStudents() {
  const {searchString} = yield getState('admin.locatorStudentPicker')
  const currentFacilityId = yield getState('admin.currentFacilityId')
  const apiEndpoint = (currentFacilityId !== '')? `${URL_API_ADMIN_ROOT}/facility/${currentFacilityId}/at_user` : `${URL_API_ADMIN_ROOT}/at_user`
  const obj = yield get(
    apiEndpoint,
    {searchString, pageSize: 10}
  )
  if(yield uiDecoration(obj, false))
    yield put(searchLocatorStudentsResponse(obj.payload))
  else yield nextActionOnError(obj)
}

export function* getLocatorPageInfo() {
  yield put(resetSearchLocatorStudentsResponse()) 
  
  yield all([
    getLocatorStudentInfo(),
    requestSyncQueues(),
    fetchStudentLocatorScheduleAttendance()
  ])
}

function* getLocatorStudentInfo(){
  yield(resetLocatorStudentInfo())  

  const {selectedStudent} = yield getState('admin.locatorStudentPicker')
  const apiEndpoint = `${URL_API_ADMIN_ROOT}/at_user/${selectedStudent.id}/locatorInfo`
  const obj = yield get(apiEndpoint)

  if(yield uiDecoration(obj, false))
  {
    yield put(setStudentLocatorInfo(obj.payload))  
  }
  else yield nextActionOnError(obj)
}

export function* requestSyncQueues() { 
  const {isSyncing} = yield getState('admin.queueSyncResponse')
  if(isSyncing){
    return
  }

  yield(resetSyncQueuesState())   

  const syncResult = yield post(`${URL_API_ADMIN_ROOT}/worker_job/officeQueue`)

  if(yield uiDecoration(syncResult, false)){    
    yield put(setSyncQueuesResponse({synced: true, syncSuccess: true, isSyncing: false}))

    const {selectedStudent} = yield getState('admin.locatorStudentPicker')
    if(selectedStudent.id != null){
      yield(getQueueInfo(selectedStudent.id))
    }    
  }
  else{
    yield put(setSyncQueuesResponse({synced: true, syncSuccess: false, isSyncing: false}))
  }
}

function* getQueueInfo(atUserId){
  const apiEndpoint = `${URL_API_ADMIN_ROOT}/at_user/${atUserId}/queueInfo`
  const obj = yield get(apiEndpoint)
  if(yield uiDecoration(obj, false))
  {
    if(obj.payload == null){
      return yield put(setStudentLocatorInfo({queueInfo: { retrieved: true}}))
    }
    yield put(setStudentLocatorInfo({queueInfo: { ...obj.payload, retrieved: true}}))
  }
}

function* resetLocatorStudentInfo(){
  const defaultState = yield getState('admin.locatorStudentInfoDefault')
  yield put(setStudentLocatorInfo(defaultState))
}

function* resetSyncQueuesState(){
  yield put(setSyncQueuesResponse({synced: false, syncSuccess: null, isSyncing: true}))
}

const delayTakeEvery = (action, fn, miliseconds=300) => {
  function* watchInput(payload) {
    yield delay(miliseconds)
    yield fn(payload)
  }
  return takeLatest(action, watchInput)
}

export function* bellScheduleFetchByFaciltyId(facilityId) {
  if(!facilityId) return;
  const obj = yield get(`${URL_API_ADMIN_ROOT}/facility/${facilityId}/bell_schedule`)
  if(yield uiDecoration(obj, false))
    yield put(bellScheduleFetchByFaciltyIdResponse(obj.data))
}

export function* adminSetCurrentPath() {
  const admin = yield getState('admin')
  if(cachedFacilityId !== admin.currentFacilityId) {
    cachedFacilityId = admin.currentFacilityId //first we make sure to re-cache the facilityId so we don't get unexpected circular side-effects.
    switch(admin.currentPage.to) {
      case URL_BELL_SCHEDULE :
        yield bellScheduleFetchByFaciltyId(admin.currentFacilityId)
        break
      case URL_SEMESTER_RULES :
        yield semesterRuleFetchByFaciltyId(admin.currentFacilityId)
        break
      default :
    }
  }
}

const bellScheduleFilter = filter([
  'id','description','timezone',
  'atReasonDefaultId', 'atReasonPresentId','atReasonOutId','atReasonTardyId','atReasonTardyExcusedId','atReasonAbsentId','atReasonAbsentExcusedId',
  'atTypePresentId','atTypeTardyId','atTypeTardyExcusedId','atTypeAbsentId','atTypeAbsentExcusedId', 'atTypeOutOfBuildingId',
  'bellPeriodPreStartPresentSeconds','bellPeriodPostStartTardySeconds','bellPeriodPostStartAbsentSeconds','maxNumTardyBeforeAbsent',
  'paMode', 'isPaEnabled', 'isOutOfBuildingPropagationEnabled', 'isNonAttendancePeriodsEnabled'
])

export function* bellScheduleRulesSave() {
  const bellSchedule = yield getState('admin.dialogs.bellRulesEditor.bellSchedule')
  const obj = yield post(`${URL_API_ADMIN_ROOT}/facility/${bellSchedule.facilityId}/bell_schedule`,
    bellScheduleFilter(bellSchedule)
  )
  if(yield dispatchWithUI(obj, bellRulesEditSaveResponse)) {
    yield put(bellRulesEditDone())
  }
}

export function* messagingFetch(params) {
  const obj = yield get(`${URL_API_ADMIN_ROOT}/facility/${params.entityId}/content_roles`)
  /* Donation time project - this had been intended as a transform of jsbDocumentation into something even more ... useful?
  if(obj.success && obj.data) {
    obj.data = obj.data.map((role) => {
      role.jsbDocumentation = role.jsbDocumentation
      return role
    })
  }*/
  yield dispatchWithUI(obj, messagingFetchResponse)
}

export function* messagingEditSaveContent() {
  const messageContent = yield getState('admin.dialogs.messageContentEditor.messageContent')
  const obj = yield post(`${URL_API_ADMIN_ROOT}/messaging_content/${messageContent.id}`, messageContent)
  yield dispatchWithUI(obj, messagingEditSaveContentResponse)
}

export function* messagingEditPreviewContent() {
  const messageContent = yield getState('admin.dialogs.messageContentEditor.messageContent')
  const obj = yield post(`${URL_API_ADMIN_ROOT}/messaging_content/${messageContent.id}/test_render`, messageContent)
  yield dispatchWithUI(obj, messagingEditPreviewContentResponse)
}

export function* kioskDefaultConfigurationEdit(payload) {
  const obj = yield get(`${URL_API_ADMIN_ROOT}/kiosk_default_configuration/${payload.parentType}/${payload.parentId}`)
  yield dispatchWithUI(obj, ()=>kioskDefaultConfigurationEditResponse(obj.payload), false)
}

export function* kioskDefaultConfigurationEditSave () {
  const kioskConfigurationEditor = yield getState('admin.dialogs.kioskConfigurationEditor')
  if(kioskConfigurationEditor.kiosk) {
    console.error(`Unable to save as UI is probably in an incorrect state. Kiosk should not be set, but it is!`, JSON.stringify(kioskConfigurationEditor.kiosk, null, 2) )
    return
  }
  const kioskDefaultConfiguration = kioskConfigurationEditor.kioskDefaultConfiguration
  const obj = yield post(
    `${URL_API_ADMIN_ROOT}/kiosk_default_configuration/${kioskDefaultConfiguration.id}`,
    {kioskDefaultConfiguration}
  )

  if(kioskDefaultConfiguration.locationId !== ID_ZERO) {
    if (yield uiDecoration(obj)) {
      yield put(saveLocationSettingsResponse(obj.payload.kioskDefaultConfiguration))
      yield put(closeKioskEditor())
    }
  } else {
    yield dispatchWithUI(obj, closeKioskEditor)
  }
}

export function* kioskDefaultConfigurationPropSetAndForcePush (payload) {
  const {id, propName, value} = payload
  const obj = yield post(
    `${URL_API_ADMIN_ROOT}/kiosk_default_configuration/${id}/update_setting`,
    {propName, val:value, forcePush:true}
  )

  if (yield uiDecoration(obj)) {
    if(obj.payload.kioskDefaultConfiguration !== ID_ZERO) {
      yield put(saveLocationSettingsResponse(obj.payload.kioskDefaultConfiguration))
      yield fetchKiosksByLocation(obj.payload.kioskDefaultConfiguration.locationId)
    }
  }
}

export function* kioskDefaultConfigurationForcePush (id) {
  const obj = yield post(`${URL_API_ADMIN_ROOT}/kiosk_default_configuration/${id}/force_push`)
  if(yield dispatchWithUI(obj, closeKioskEditor)) {
    if(obj.payload.level === KDCL_LOCATION) {
      yield fetchKiosksByLocation(obj.payload.locationId)
    }
  }
}

export function* loginSend ({email, password}) {
  const obj = yield post(
    `${URL_API_ADMIN_ROOT}/login`,
    {email,password}
  )
  if(!obj.success) {
    yield put(snackMessageShow(determineFailedLoginMessage(obj) , STATUS_MESSAGE_TYPE_ERROR, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  } else {
    if(obj.nextAction === 'start') {
      yield startAdmin(obj)
    } else {
      console.log(`admin loginSend - nextAction unknown:${obj.nextAction}`, obj)
      window.location.reload()
      //alert('Please reload the page.')
    }
  }
}

export function* logoutSend() {
  const obj = yield post(`${URL_API_ROOT}/user_account/logout`, {})
  if(!obj.success) {
    yield put(snackMessageShow('There was an error logging you out. Please try again' , STATUS_MESSAGE_TYPE_ERROR, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  } else {
    if(obj.nextAction === 'reload') {
      window.location.reload()
    } else {
      console.log(`admin logoutSend - nextAction unknown:${obj.nextAction}`, obj)
      alert('Please reload the page.')
    }
  }
}

export function* reportTypesFetch() {
  const obj = yield get(`${URL_API_ADMIN_ROOT}/report_types`)
  yield dispatchWithUI(obj, ()=>reportTypesFetchResponse(obj.payload))
}

export function* reportPageFetchReportById() {
  const {reportTypeId, lastDateYMD, firstDateYMD, jsbCustomConfiguration:{ settings }} = yield getState('admin.reportPage')
  if(reportTypeId === ID_ZERO) return

  yield put(reportPageFetchReportByIdResponse(false))

  const facilityId = yield getState('admin.currentFacilityId')
  const obj = yield get(`${URL_API_ADMIN_ROOT}/report/by_id/${reportTypeId}`, {
    facilityId,
    firstDateYMD,
    lastDateYMD,
    settings,
  })
  yield dispatchWithUI(obj, ()=>reportPageFetchReportByIdResponse(formatReportListProperties(obj.payload)))
}

export function* studentDetailPageReportFetchAttendanceLog() {
  const {
    attendanceReport: {
      lastDateYMD,
      firstDateYMD
    },
    student: {
      attendancePasscode
    }
  } = yield getState('admin.studentDetailPage')

  yield put(studentDetailPageReportFetchAttendanceLogResponse(false))

  const obj = yield get(`${URL_API_ADMIN_ROOT}/report/at_user_attendance_log`, {
    attendancePasscode,
    firstDateYMD,
    lastDateYMD,
  })
  yield dispatchWithUI(obj, ()=>studentDetailPageReportFetchAttendanceLogResponse(formatReportListProperties(obj.payload)))
}

export function* userAccountSearch(payload) {
  const search = yield getState('admin.userAccountSearchPage.search')
  const facilityId = yield getState('admin.currentFacilityId')

  const roleSearch = (search.role && search.role !== 'all')? `&role=${search.role}` : ''

  const facilityIdSearch = (facilityId && facilityId !== 0)? `&facilityId=${facilityId}` : ''

  const obj = yield get(`${URL_API_ADMIN_ROOT}/users?searchString=${search.searchString}${roleSearch}${facilityIdSearch}`)

  yield dispatchWithUI(obj, ()=>userAccountSearchResponse(obj.payload))

}

export function* comingSoon(message) {
  const theMessage = (typeof message === 'string')? message : 'Feature coming soon'
  yield put(snackMessageShow(theMessage, STATUS_MESSAGE_TYPE_INFO, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS))
}

export function* userAccountEditSave() {
  const userAccount = yield getState('admin.dialogs.userAccountEditor.userAccount')
  let obj
  if(userAccount.id) {
    obj = yield post(`${URL_API_ADMIN_ROOT}/user/${userAccount.id}`, userAccount)
  } else {
    obj = yield post(`${URL_API_ADMIN_ROOT}/user`, userAccount)
  }

  if(yield dispatchWithUI(obj, ()=>userAccountEditSaveResponse(obj.payload))) {
    yield put(userAccountEditDone())
  }
}

export function* userAccountUpload() {
  const userAccount = yield getState('admin.dialogs.userAccountUploader.userAccount')
  let obj = yield post(`${URL_API_ADMIN_ROOT}/users`, userAccount)

  if(yield dispatchWithUI(obj, ()=>userAccountCloseUploader(obj.payload))) {
    yield put(userAccountEditDone())
  }
}

export function* userAccountEdit(id) {
  const facilityId = yield getState('admin.currentFacilityId')
  if(!facilityId && !id) {
    yield put(snackMessageError('Please choose a specific facility before creating a user'))
  }
}

export function* userAccountOpenUploader() {
  const facilityId = yield getState('admin.currentFacilityId')
  if(!facilityId) {
    yield put(snackMessageError('Please choose a specific facility before uploading users'))
  }
}

export function* userAccountDelete() {
  const userAccount = yield getState('admin.dialogs.userAccountEditor.userAccount')
  const obj = yield del(`${URL_API_ADMIN_ROOT}/user/${userAccount.id}`, userAccount)
  if(yield dispatchWithUI(obj, ()=>{userAccount.isDeleted = true; return userAccountEditSaveResponse(userAccount)})) {
    yield put(userAccountEditDone())
  }
}
/*
export function* homePageDataFetch() {
  const obj = yield axios.get('${URL_API_ADMIN_ROOT}/at_record/days_ago/30')
  if(!obj.success) {
    yield put(snackMessageError('There was an error connecting to the server. Please reload the page', DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  } else {
    yield put(homePageDataFetchResponse(obj.payload))
  }
}
*/

export function* emailTest() {
  let obj = yield post(`${URL_API_ADMIN_ROOT}/email_test`)

  yield uiDecoration(obj)
}


export function* forgotPasswordSend({email}) {
  if(!email || email.indexOf('@') < 0) {
    yield put(snackMessageError('Please enter a valid email address.'))
    return
  }
  const obj = yield post(
    `${URL_API_ROOT}/user_account/password/forgot`,
    {email}
  )
  yield uiDecoration(obj)
}

export function* setUrlSearchProperty(payload) {
  const params = queryString.parse(window.location.search)
  if(!payload.hasOwnProperty('val') || payload.val === null) {
    delete params[payload.propertyName]
  }

  let query = queryString.stringify(params)
  if(query) {
    window.history.pushState(null,null, window.location.pathname + '?'+ query)
  } else {
    window.history.pushState(null,null, window.location.pathname)
  }
  yield searchFromUrl()
}


export function* searchFromUrl(pathChange) {
  const {searchContext, searchOnPathChange} = yield getState('admin.currentPage')
  const params = queryString.parse(window.location.search)
  const searchParams = {}
  const pathSplit = window.location.pathname.split('/')
  const entityId  = parseInt(pathSplit[2])

  if(pathChange && !searchOnPathChange) return

  if(!isNaN(entityId)) {
    searchParams.entityId = entityId
  } else {
    searchParams.entityId = ID_ZERO
  }
  if(params.locationName) {
    searchParams.locationName = params.locationName
  }
  if(params.buildingId) {
    searchParams.buildingId = params.buildingId
  }

  switch(searchContext) {
    case SEARCH_CONTEXT_LOCATIONS_PAGE :
      yield put(searchLocationsBy(searchParams))
      break
    case SEARCH_CONTEXT_MESSAGING_PAGE :
      yield messagingFetch(searchParams)
      break
    case SEARCH_CONTEXT_USER_ACCOUNT_PAGE :
      yield userAccountSearch({})
      break
    case SEARCH_CONTEXT_AT_USER_LIST_PAGE :
      yield searchStudents()
      break
    default :
  }
}


/**
 * Updates the url on location search change.
 * Primary purpose is to for bookmarking, sharing, & to keep the url clean.
 * @returns void
 */
export function* searchLocationsByUrlUpdater() {
  const stateParams = yield getState('admin.locationPage.search.params')
  const params = {}
  if(stateParams.buildingId && stateParams.buildingId > 0) {
    params.buildingId = stateParams.buildingId
  }
  if(stateParams.locationName) {
    params.locationName = stateParams.locationName
  }
  let query = queryString.stringify(params)
  if(query) {
    window.history.pushState(null,null, window.location.pathname + '?'+ query)
  } else {
    window.history.pushState(null,null, window.location.pathname)
  }
}

export function* buildingChooserOpen() {
  const resp = yield get(`${URL_API_ADMIN_ROOT}/buildings`, {all:true})
  yield dispatchWithUI(resp, buildingChooserOpenResponse, false)
}

export function* buildingChooserSave() {
  const {buildings} = yield getState('admin.buildingChooser')
  const resp = yield post(`${URL_API_ADMIN_ROOT}/buildings`, {buildings})
  yield dispatchWithUI(resp, buildingChooseConfirmResponse)

  yield updateLocationsWhenSynced(resp.payload.waitSyncLocationsAfter, resp.payload.enabledBuildings.map(b => b.id))
}

function* updateLocationsWhenSynced(waitSyncLocationsAfter, buildingIds) {
  const resp = yield post(`${URL_API_ADMIN_ROOT}/location/all_synced`, {waitSyncLocationsAfter})
  yield put(locationsSyncWaitResponse({...resp.payload, buildingIds})) 

  const {search} = yield getState('admin.locationPage')
  yield put(searchLocationsBy(Object.assign({}, search.params)))
}

export function* entityChooserOpen() {
  const resp = yield get(`${URL_API_ADMIN_ROOT}/entities`, {all:true})
  yield dispatchWithUI(resp, entityChooserOpenResponse, false)
}

export function* entityChooserValidateSet({id, isEnabled}) {
  const entities = yield getState('admin.allFacilities')
  const entity = entities.find(e => e.id === id);
  if(entity.isEnabled){
    yield put(snackMessageError(`Entity ${entity.displayName} is already enabled.\n Entities that are enabled cannot be disabled`))
  } else {
    yield put((entityChooserSetIsEnabled({id, isEnabled})))
  }
}

export function* buildingChooserValidateSet({id, isEnabled}) {
  const buildings = yield getState('admin.allBuildings')
  const building = buildings.find(e => e.id === id);
  if(building){
    yield put(snackMessageError(`Building ${building.displayName} is already enabled.\n Buildings that are enabled cannot be disabled`))
  } else {
    yield put((buildingChooserSetIsEnabled({id, isEnabled})))
  }
}

export function* bgPoll(pollingFn, delayMS=5000) {
  try {
    while (true) {
      yield call(pollingFn)
      yield delay(delayMS)
    }
  } finally {
    yield cancelled()
  }
}

export function* startAllWorkerJobsPoll(payload) {
  const {msPolling=1000} = payload
  const bgPollTask = yield fork(bgPoll, fetchAllWorkerJobs, msPolling)
  yield take(ADMIN_STOP_ALL_WORKER_JOBS_POLL)
  yield cancel(bgPollTask)
}

export function* homePagePoll(payload) {
  const {msPolling=5000} = payload
  const bgPollTask = yield fork(bgPoll, fetchHomePageData, msPolling)
  yield take(STOP_HOME_PAGE_POLL)
  yield cancel(bgPollTask)
}

export function* dangerousPirateGeneratePositiveAttendance(payload) {
  if(yield getState('admin.piratesAndDragonsEnabled')) {
    yield put(dangerousUpdateGeneralProperty('pirateGeneratePositiveAttendance', true))
    const {pirateGeneratePositiveAttendanceYMDStart, pirateGeneratePositiveAttendanceYMDEnd, pirateGeneratePositiveAttendanceAtUserIdCsv} = yield getState('admin')
    const atUserIds = pirateGeneratePositiveAttendanceAtUserIdCsv ? pirateGeneratePositiveAttendanceAtUserIdCsv.split(',') : []
    const resp = yield post(`${URL_API_ROOT}/pirates/generate_positive_attendance`,
      {
        atUserIds,
        pirateGeneratePositiveAttendanceYMDStart,
        pirateGeneratePositiveAttendanceYMDEnd
      })
    yield uiDecoration(resp)
    yield put(dangerousUpdateGeneralProperty('pirateGeneratePositiveAttendance', false))
  }
}

export function* dangerousPirateDeletePositiveAttendanceFromDB() {
  if(yield getState('admin.piratesAndDragonsEnabled')) {
    yield put(dangerousUpdateGeneralProperty('pirateDeletePositiveAttendanceFromDB', true))
    const resp = yield del(`${URL_API_ROOT}/pirates/delete_positive_attendance`)
    yield uiDecoration(resp)
    yield put(dangerousUpdateGeneralProperty('pirateDeletePositiveAttendanceFromDB', false))
  }
}

export function* dangerousPirateDeleteAllAttendanceFromDB() {
  if(yield getState('admin.piratesAndDragonsEnabled')) {
    yield put(dangerousUpdateGeneralProperty('pirateDeleteAllAttendanceFromDB', true))
    const resp = yield del(`${URL_API_ROOT}/pirates/delete_all_attendance`)
    yield uiDecoration(resp)
    yield put(dangerousUpdateGeneralProperty('pirateDeleteAllAttendanceFromDB', false))
  }
}

export function* pirateDeleteNegativeAttendanceFromDB() {
  if(yield getState('admin.piratesAndDragonsEnabled')) {
    yield put(pirateUpdateGeneralProperty('deleteNegativeAttendanceFromDB', true))
    const resp = yield del(`${URL_API_ROOT}/pirates/delete_negative_attendance`)
    yield uiDecoration(resp)
    yield put(pirateUpdateGeneralProperty('deleteNegativeAttendanceFromDB', false))
  }
}

export function* pirateMarkNegativeAttendanceForDeletionFromQ() {
  if(yield getState('admin.piratesAndDragonsEnabled')) {
    yield put(pirateUpdateGeneralProperty('markNegativeAttendanceForDeletionFromQ', true))
    const resp = yield post(`${URL_API_ROOT}/pirates/mark_negative_attendance_for_q_deletion`)
    yield uiDecoration(resp)
    yield put(pirateUpdateGeneralProperty('markNegativeAttendanceForDeletionFromQ', false))
  }
}

export function* pirateAddKiosksToAllLocations() {
  if(yield getState('admin.piratesAndDragonsEnabled')) {
    yield put(pirateUpdateGeneralProperty('addKiosksToAllLocations', true))
    const resp = yield post(`${URL_API_ROOT}/pirates/generate_kiosks`)
    yield uiDecoration(resp)
    yield put(pirateUpdateGeneralProperty('addKiosksToAllLocations', false))
  }
}

export function* pirateGenerateNegativeAttendance() {
  if(yield getState('admin.piratesAndDragonsEnabled')) {
    const negativeAttendance = yield getState('admin.pirate.negativeAttendance')
    yield put(pirateUpdateGeneralProperty('generatingNegativeAttendance', true))
    const resp = yield post(`${URL_API_ROOT}/pirates/generate_negative_attendance`, negativeAttendance)
    yield uiDecoration(resp)
    console.log(`Generate negative attendance response:`, resp.payload)
    yield put(pirateUpdateGeneralProperty('generatingNegativeAttendance', false))
  }
}

export function* ppkEdit(payload) {
  if(payload.locationId) {
    const obj = yield get(`${URL_API_ADMIN_ROOT}/location/${payload.locationId}`, {
      withKioskDefaultConfiguration:true
    })
    if(yield uiDecoration(obj, false)) {
      const location = obj.payload
      yield put(ppkConfigurationEditResponse({
        kiosk:{
          id: -1,
          isEnabled: true,
          kioskType: KIOSK_TYPE_PPK,
          ppkGroupNum:'',
          ppkDeviceNum:'',
          ppkKey:'',
          locationId: obj.payload.id,
          atTypeId: location.kioskDefaultConfiguration.atTypeId,
          requestSendReport: location.kioskDefaultConfiguration.requestSendReport,
          maxLeaseLength: location.kioskDefaultConfiguration.maxLeaseLength,
          customAttendanceConfiguration: {
            facilitiesOverrides: []
          }
        },
        location,
      }))
    }
  } else if(payload.id) {
    let obj = yield get(`${URL_API_ADMIN_ROOT}/kiosk/${payload.id}`)
    if(yield uiDecoration(obj, false)) {
      const kiosk = obj.payload
      obj = yield get(`${URL_API_ADMIN_ROOT}/location/${kiosk.locationId}`, {
        withKioskDefaultConfiguration:true
      })
      if(yield uiDecoration(obj, false)) {
        const location = obj.payload
        yield put(ppkConfigurationEditResponse({
          kiosk,
          location
        }))
      }
    }
  }
}

export function* ppkEditSave () {
  const {kiosk} = yield getState('admin.dialogs.ppkEditor')
  let obj
  if(!kiosk) {
    console.error(`Unable to save as ppkEditor UI is probably in an incorrect state! ` )
    return
  }
  if(kiosk.id < 1) {
    kiosk.ppkKey = computePPKKey(kiosk.ppkGroupNum, kiosk.ppkDeviceNum)
    if(kiosk.ppkKey === '') {
      yield put(snackMessageError('Please choose valid Group and Device IDs'))
      return
    }
    obj = yield post(
      `${URL_API_ADMIN_ROOT}/ppk`,
      {kiosk}
    )
  } else {
    obj = yield post(
      `${URL_API_ADMIN_ROOT}/kiosk/${kiosk.id}`,
      {kiosk}
    )
  }

  if(yield uiDecoration(obj)) {
    yield put(saveKioskSettingsResponse(obj.payload))
    yield put(ppkEditClose())
  }
}

export function* sendEntityIsEnabled() {
  const {entities} = yield getState('admin.entityChooser')

  const entityIds = entities.reduce((acc, entity) => {
    if(entity.isEnabled) {
      acc.push(entity.id)
    }
    return acc
  }, [])

  if(entityIds.length === 0) {
    return
  }

  const obj = yield post(`${URL_API_ROOT}/admin/set_entities_enabled`, {
    entityIds
  })

  if(yield uiDecoration(obj)) {
    yield put(entitySetIsEnabledResponse(obj.payload));
    yield put(hideAddEntitiesDialog());
  }
}

export function* fetchAllCourses() {  
  const obj = yield get(`${URL_API_ADMIN_ROOT}/course`);
  if(yield dispatchWithUI(obj, coursesFetchResponse, false ) ) {
  } else {
    console.warn("Error getting courses ", obj);
  }
};

export function* attendanceMonitorCoursesSave(payload) {
  const chooserCourses = yield getState('admin.attendanceMonitorCoursesChooser.courses');
  const dbCourses = yield getState('admin.courses');

  const courses = chooserCourses.filter(chooserCourse => {
    const dbCourse = dbCourses.find(dbCourse => dbCourse.id === chooserCourse.id);
    return dbCourse && dbCourse.isAttendanceMonitorEnabled !== chooserCourse.isAttendanceMonitorEnabled;
  });

  const resp = yield post(`${URL_API_ADMIN_ROOT}/course`, {courses});
  if(yield dispatchWithUI(resp, attendanceMonitorCoursesSaveResponse)) {
    yield put(attendanceMonitorCoursesEditDone(resp.payload));
  };
};

function* handleWorkerJobEdit(action) {
  const workerJobs = yield select(state => state.admin.workerJobs);
  const workerJob = workerJobs.find(job => job.id === action.payload);

  if (workerJob && workerJob.name === 'WORKER_JOB_NAME_ATTENDANCE_MONITOR') {
    yield put(coursesFetch());
    yield put(locationsFetch());
  };
};

export function* fetchAllLocations() {  
  const obj = yield get(`${URL_API_ADMIN_ROOT}/location`);
  if(yield dispatchWithUI(obj, locationsFetchResponse, false ) ) {
  } else {
    console.warn("Error getting courses ", obj);
  }
};

export function* attendanceMonitorLocationsSave(payload) {
  const chooserLocations = yield getState('admin.attendanceMonitorLocationsChooser.locations');
  const dblocations = yield getState('admin.allLocations');

  const locations = chooserLocations.filter(chooserLocation => {
    const dbLocation = dblocations.find(dbLocation => dbLocation.id === chooserLocation.id);
    return dbLocation && dbLocation.isAttendanceMonitorEnabled !== chooserLocation.isAttendanceMonitorEnabled;
  });

  const resp = yield post(`${URL_API_ADMIN_ROOT}/location`, {locations: locations});
  if(yield dispatchWithUI(resp, attendanceMonitorLocationsSaveResponse)) {
    yield put(attendanceMonitorLocationsEditDone(resp.payload));
  }
};

export function* startSyncSchoolYearsSaga() {
  const obj = yield post(`${URL_API_ADMIN_ROOT}/sync_school_years`, {});
  if(!(yield uiDecoration(obj))) {
      yield nextActionOnError(obj);
  }
  yield put(startSyncSchoolYearsStatePolling());
};

export function* startSchoolYearsStatePollingSaga() {
  const bgPollTask = yield fork(bgPoll, schoolYearsFetch, 1000);
  yield take(ADMIN_STOP_SCHOOL_YEARS_STATE_POLLING);
  yield cancel(bgPollTask);
}

const workerJobStatusCompiler = (workerJob) => {
  let jobStatus = {
    displayName: workerJob.displayName,
    summary: 'Not started',
    complete: false,
    displayMessage: ''
  };

  if (workerJob.uLastRunStart > 0) {
    jobStatus.complete = workerJob.runStatus === SYNC_RUN_STATUS_WAITING;
    jobStatus.summary = workerJob.runStatusSummary;
  }

  jobStatus.displayMessage = `${jobStatus.displayName}: ${jobStatus.summary}`;

  return jobStatus;
};


let synchronizerNotRunningCounter = 0;
let notConnectedToQCounter = 0;
let connectedCounter = 0;

export function* schoolYearsFetch() {
  const resp = yield get(`${URL_API_ADMIN_ROOT}/school_years`);
  if(resp.payload && resp.payload.workerJob) {
    const payload = resp.payload;
    const workerJobStatus = workerJobStatusCompiler(payload.workerJob);
    if(!payload.synchronizerRunning) {
      synchronizerNotRunningCounter++;
      const message = `Synchronizer may not be running. Just a moment... ${synchronizerNotRunningCounter}`;
      yield put(snackMessageError(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER));
      return;
    } else if(!payload.skywardConnectionVerified) {
      synchronizerNotRunningCounter = 0;
      notConnectedToQCounter++;
      const message = [`Not connected to Skyward Q. Just a moment... ${notConnectedToQCounter}`].concat(workerJobStatus.displayMessage);
      yield put(snackMessageWarning(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER));
    } else {
      synchronizerNotRunningCounter = 0;
      notConnectedToQCounter = 0;
      connectedCounter++;
      const message = [`Connected to Skyward Q. Pulling data... ${connectedCounter++}`].concat(workerJobStatus.displayMessage);
      yield put(snackMessageSuccess(message));
    }

    if(workerJobStatus.complete) {
      if(yield dispatchWithUI(resp, schoolYearsFetchResponse, false )) {
      } else {
        console.warn("Error getting app school years", payload);
      }
      yield put(stopSyncSchoolYearsStatePolling());
    }
  }
};
  
export function* migrateSchoolYearSaga({email, password}) {
  try {
    const resp = yield post(`${URL_API_ADMIN_ROOT}/migrate_school_year`, {email, password});
    if(yield dispatchWithUI(resp, migrateSchoolYearResponse, true )){
    } else {
      yield put(migrateSchoolYearError(resp));
    }
  } catch (error) {
    console.error(error);
    yield put(snackMessageError(`School year migration has failed`));
  }
};

export function* overridableAtTypesSaveSaga() {
  const chooserAtTypes = yield getState('admin.overridableAttendanceTypesChooser.atTypes');
  const dbAtTypes = yield getState('admin.atTypes');

  const atTypes = chooserAtTypes.filter(chooserAtType => {
    const dbAtType = dbAtTypes.find(dbAtType => dbAtType.id === chooserAtType.id);
    return dbAtType && dbAtType.kioskCanOverride !== chooserAtType.kioskCanOverride;
  });
  const resp = yield post(`${URL_API_ADMIN_ROOT}/at_type`, {atTypes});
  if(yield dispatchWithUI(resp, () => overridableAtTypesSaveResponse(resp.payload), true)) {
    yield put(overridableAtTypesEditDone (resp.payload));
  }
};

export function* semesterRuleFetchByFaciltyId(facilityId) {
  if(!facilityId) return;
  const obj = yield get(`${URL_API_ADMIN_ROOT}/facility/${facilityId}/attendance_term`)
  if(yield uiDecoration(obj, false))
    yield put(semesterRuleFetchByFaciltyIdResponse(obj.payload))
}

export function* semesterRuleEditSave() {
  const editorAttendanceTerms = yield getState('admin.dialogs.semesterRuleEditor.attendanceTerms');
  const dbAttendanceterms = yield getState('admin.currentSemesterRuleSummary.attendanceTerms');

  const attendanceTerms = editorAttendanceTerms.filter(editorAttendanceTerm => {
    const dbAttendanceTerm = dbAttendanceterms.find(dbTerm => dbTerm.id === editorAttendanceTerm.id);
    return dbAttendanceTerm && dbAttendanceTerm.isAttendanceCountResetEnabled !== editorAttendanceTerm.isAttendanceCountResetEnabled;
  });

  const obj = yield post(`${URL_API_ADMIN_ROOT}/attendance_term`, {attendanceTerms: attendanceTerms});

  if(yield dispatchWithUI(obj, semesterRuleEditSaveResponse)) {
      yield put(semesterRuleEditDone())
  }
}

export function* adminConnectSkywardAccount() {
  const obj = yield post(`${URL_API_ROOT}/skywardAccount/redirectToConnect`, {
    returnClientAddress: URL_ACCOUNT_SETTINGS
  });
  if(yield uiDecoration(obj)){
    changePageToHtml(obj.data)
  }  
}

export function* adminRemoveSkywardAccount() {
  const obj = yield post(`${URL_API_ROOT}/skywardAccount/remove`);
  if(yield uiDecoration(obj)){
    yield put(adminRemoveSkywardState())
  }
}

export const adminWatches = [
  takeEvery(ADMIN_BOOT, withErrorHandling(adminBoot)),
  takeEvery(ADMIN_FETCH_RICH_LOCATION_BY_ID, payloadOnly(withErrorHandling(fetchKiosksByLocation))),
  takeEvery(ADMIN_SAVE_KIOSK_SETTINGS, withErrorHandling(saveKioskSettings)),
  takeEvery(ADMIN_SAVE_LOCATION_SETTINGS, withErrorHandling(saveLocationSettings)),
  delayTakeEvery(ADMIN_SEARCH_STUDENTS, withErrorHandling(searchStudents)),
  delayTakeEvery(ADMIN_SEARCH_LOCATOR_STUDENTS, withErrorHandling(searchLocatorStudents)),
  takeEvery(ADMIN_APP_SETTINGS_FETCH, withErrorHandling(fetchAppSettings)),
  takeEvery(ADMIN_SELECT_LOCATOR_STUDENT, withErrorHandling(getLocatorPageInfo)),
  takeEvery(ADMIN_SET_LOCATOR_SCHEDULE_PICKER_DATE, withErrorHandling(fetchStudentLocatorScheduleAttendance)),
  takeEvery(ADMIN_REQUEST_SYNC_QUEUES, withErrorHandling(requestSyncQueues)),
  takeEvery(ADMIN_APP_SETTINGS_SAVE, withErrorHandling(saveAppSettings)),
  takeEvery(ADMIN_DELETE_KIOSK, withErrorHandling(deleteKiosk)),
  takeEvery(ADMIN_FETCH_RICH_STUDENT_BY_ID, payloadOnly(withErrorHandling(fetchRichStudentById))),
  takeEvery(FETCH_STUDENT_PAGE_SCHEDULE_ATTENDANCE, payloadOnly(withErrorHandling(fetchStudentPageScheduleAttendance))),
  takeEvery(FETCH_STUDENT_PAGE_LIST_ATTENDANCE, payloadOnly(withErrorHandling(fetchStudentPageListAttendance))),
  takeEvery(SET_STUDENT_DETAIL_PAGE_ATTENDANCE_VIEW, payloadOnly(withErrorHandling(setStudentDetailPageAttendanceView))),
  takeEvery(ADMIN_SAVE_STUDENT, withErrorHandling(saveStudent)),
  takeEvery(ADMIN_AT_RECORD_EDIT_SAVE, withErrorHandling(saveAtRecord)),
  takeEvery(FETCH_SCHEDULE_DISPLAY_SETTINGS, payloadOnly(withErrorHandling(fetchSchDispSettings))),
  takeEvery(SAVE_SCHEDULE_DISPLAY_SETTINGS, payloadOnly(withErrorHandling(saveSchDispSettings))),
  takeLatest(ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID, payloadOnly(withErrorHandling(bellScheduleFetchByFaciltyId))),
  takeLatest(ADMIN_SET_CURRENT_PATH, payloadOnly(withErrorHandling(adminSetCurrentPath))),
  takeLatest(ADMIN_BELL_RULES_EDIT_SAVE, payloadOnly(withErrorHandling(bellScheduleRulesSave))),
  //takeLatest(ADMIN_MESSAGING_FETCH, messagingFetch),
  takeLatest(ADMIN_MESSAGING_EDIT_SAVE_CONTENT, payloadOnly(withErrorHandling(messagingEditSaveContent))),
  takeLatest(MESSAGING_EDIT_PREVIEW_CONTENT, payloadOnly(withErrorHandling(messagingEditPreviewContent))),
  takeLatest(ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT, payloadOnly(withErrorHandling(kioskDefaultConfigurationEdit))),
  takeLatest(ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_SAVE, payloadOnly(withErrorHandling(kioskDefaultConfigurationEditSave))),
  takeLatest(ADMIN_KIOSK_DEFAULT_CONFIGURATION_FORCE_PUSH, payloadOnly(withErrorHandling(kioskDefaultConfigurationForcePush))),
  takeEvery(ADMIN_LOGIN_SEND, payloadOnly(withErrorHandling(loginSend))),
  takeEvery(ADMIN_LOGOUT_SEND, withErrorHandling(logoutSend)),
  takeEvery(ADMIN_USER_ACCOUNT_EDIT, payloadOnly(withErrorHandling(userAccountEdit))),
  takeLatest(ADMIN_USER_ACCOUNT_EDIT_SAVE, payloadOnly(withErrorHandling(userAccountEditSave))),
  takeLatest(ADMIN_USER_ACCOUNT_OPEN_UPLOADER, payloadOnly(withErrorHandling(userAccountOpenUploader))),
  takeLatest(ADMIN_USER_ACCOUNT_UPLOAD, payloadOnly(withErrorHandling(userAccountUpload))),
  takeEvery(ADMIN_USER_ACCOUNT_DELETE, payloadOnly(withErrorHandling(userAccountDelete))),
  takeLatest(ADMIN_USER_ACCOUNT_SEARCH, payloadOnly(withErrorHandling(userAccountSearch))),
  takeLatest(ADMIN_COMING_SOON, payloadOnly(withErrorHandling(comingSoon))),
  takeLatest(ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_AND_SAVE, payloadOnly(withErrorHandling(kioskDefaultConfigurationPropSetAndForcePush))),
  //takeLatest(ADMIN_HOME_PAGE_DATA_FETCH, payloadOnly(homePageDataFetch)),
  takeLatest(ADMIN_EMAIL_TEST, payloadOnly(withErrorHandling(emailTest))),
  takeLatest(ADMIN_FORGOT_PASSWORD_SEND, payloadOnly(withErrorHandling(forgotPasswordSend))),
  takeEvery(SEARCH_FROM_URL, payloadOnly(withErrorHandling(searchFromUrl))),
  takeEvery(ADMIN_SEARCH_LOCATIONS_BY, payloadOnly(withErrorHandling(searchLocationsByUrlUpdater))),
  takeEvery(ADMIN_WORKER_JOB_EDIT_SAVE, payloadOnly(withErrorHandling(workerJobEditSave))),
  takeEvery(ADMIN_WORKER_JOB_START_STOP, payloadOnly(withErrorHandling(workerJobStartStop))),
  takeEvery(ADMIN_START_ALL_WORKER_JOBS_POLL, payloadOnly(withErrorHandling(startAllWorkerJobsPoll))),
  takeEvery(START_HOME_PAGE_DATA_POLL, payloadOnly(withErrorHandling(homePagePoll))),
  takeEvery(SET_URL_SEARCH_PROPERTY, payloadOnly(withErrorHandling(setUrlSearchProperty))),
  takeEvery(AT_RECORD_DELETE, payloadOnly(withErrorHandling(deleteAtRecord))),
  takeEvery(REPORT_TYPES_FETCH, payloadOnly(withErrorHandling(reportTypesFetch))),
  takeEvery(REPORT_PAGE_FETCH_REPORT_BY_ID, payloadOnly(withErrorHandling(reportPageFetchReportById))),
  takeEvery(WORKER_JOB_FETCH_LOGS, payloadOnly(withErrorHandling(workerJobFetchLogs))),
  takeEvery(ENTITY_CHOOSER_VALIDATE_SET, payloadOnly(withErrorHandling(entityChooserValidateSet))),
  takeEvery(ENTITY_CHOOSER_OPEN, payloadOnly(withErrorHandling(entityChooserOpen))),
  takeEvery(BUILDING_CHOOSER_OPEN, payloadOnly(withErrorHandling(buildingChooserOpen))),
  takeEvery(BUILDING_CHOOSER_VALIDATE_SET, payloadOnly(withErrorHandling(buildingChooserValidateSet))),
  takeEvery(BUILDING_CHOOSER_SET_IS_ENABLED, payloadOnly(withErrorHandling(buildingChooserSetIsEnabled))),
  takeEvery(BUILDING_CHOOSER_CONFIRM, payloadOnly(withErrorHandling(buildingChooserSave))),
  takeEvery(DANGEROUS_PIRATE_ACTION_GENERATE_POSITIVE_ATTENDANCE, payloadOnly(withErrorHandling(dangerousPirateGeneratePositiveAttendance))),
  takeEvery(DANGEROUS_PIRATE_ACTION_DELETE_POSITIVE_ATTENDANCE_FROM_DB, payloadOnly(withErrorHandling(dangerousPirateDeletePositiveAttendanceFromDB))),
  takeEvery(DANGEROUS_PIRATE_ACTION_DELETE_ALL_ATTENDANCE_FROM_DB, payloadOnly(withErrorHandling(dangerousPirateDeleteAllAttendanceFromDB))),
  takeEvery(PIRATE_GENERATE_NEGATIVE_ATTENDANCE, payloadOnly(withErrorHandling(pirateGenerateNegativeAttendance))),
  takeEvery(PIRATE_ADD_KIOSKS_TO_ALL_LOCATIONS, payloadOnly(withErrorHandling(pirateAddKiosksToAllLocations))),
  takeEvery(PIRATE_DELETE_NEGATIVE_ATTENDANCE_FROM_DB, payloadOnly(withErrorHandling(pirateDeleteNegativeAttendanceFromDB))),
  takeEvery(PIRATE_MARK_NEGATIVE_ATTENDANCE_FOR_DELETION_FROM_Q, payloadOnly(withErrorHandling(pirateMarkNegativeAttendanceForDeletionFromQ))),
  takeEvery(PPK_EDIT, payloadOnly(withErrorHandling(ppkEdit))),
  takeEvery(PPK_CONFIGURATION_EDIT_SAVE, payloadOnly(withErrorHandling(ppkEditSave))),
  takeEvery(STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG, payloadOnly(withErrorHandling(studentDetailPageReportFetchAttendanceLog))),
  takeEvery(SINGLETON_KIOSK_EDIT_FETCH, payloadOnly(withErrorHandling(singletonKioskEditFetch))),
  takeEvery(SEND_ENTITY_IS_ENABLED, payloadOnly(withErrorHandling(sendEntityIsEnabled))),
  takeEvery(ADMIN_COURSES_FETCH, withErrorHandling(fetchAllCourses)),
  takeEvery(ADMIN_AT_MONITOR_COURSES_SAVE, payloadOnly(withErrorHandling(attendanceMonitorCoursesSave))),
  takeEvery(ADMIN_WORKER_JOB_EDIT, withErrorHandling(handleWorkerJobEdit)),
  takeEvery(ADMIN_LOCATIONS_FETCH, withErrorHandling(fetchAllLocations)),
  takeEvery(ADMIN_AT_MONITOR_LOCATIONS_SAVE, payloadOnly(withErrorHandling(attendanceMonitorLocationsSave))),
  takeEvery(ADMIN_START_SYNC_SCHOOL_YEARS, payloadOnly(withErrorHandling(startSyncSchoolYearsSaga))),
  takeEvery(ADMIN_START_SCHOOL_YEARS_STATE_POLLING, payloadOnly(withErrorHandling(startSchoolYearsStatePollingSaga))),
  takeEvery(ADMIN_SCHOOL_YEARS_FETCH, withErrorHandling(schoolYearsFetch)),
  takeEvery(ADMIN_MIGRATE_SCHOOL_YEAR, payloadOnly(withErrorHandling(migrateSchoolYearSaga))),
  takeEvery(ADMIN_OVERRIDABLE_AT_TYPES_SAVE , payloadOnly(withErrorHandling(overridableAtTypesSaveSaga))),
  takeLatest(ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID, payloadOnly(withErrorHandling(semesterRuleFetchByFaciltyId))),
  takeLatest(ADMIN_SEMESTER_RULE_EDIT_SAVE, payloadOnly(withErrorHandling(semesterRuleEditSave))),
  takeEvery(ADMIN_CONNECT_SKYWARD_ACCOUNT, withErrorHandling(adminConnectSkywardAccount)),
  takeEvery(ADMIN_REMOVE_SKYWARD_ACCOUNT, withErrorHandling(adminRemoveSkywardAccount)),
]
