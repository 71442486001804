import {call, cancelled, fork, put, takeEvery, cancel, take} from 'redux-saga/effects'
import {delay} from "redux-saga"

import {
  bootPruneAppStateExcept,
  snackMessageError,
  snackMessageShow,
  snackMessageSuccess,
  snackMessageWarning
} from 'redux/RootActions'

import {
  BOOT_APP,
  LOGIN_SEND,
  LICENSE_SEND,
  SEND_ADMIN_PASSWORD,
  SEND_SKYWARD_API_INFO,
  SEND_ENTITY_SCHOOL_YEAR,
  SEND_INSTALL_COMPLETE,
  SEND_KIOSK_DEFAULT_CONFIGURATION,
  START_INSTALL_STATE_POLLING,
  STOP_INSTALL_STATE_POLLING,
  isReady,
  goToStep,
  loginShowUI,
  loginSetUser,
  startInsallStatePolling,
  stateUpdate,
  stopInsallStatePolling, settingsUpdateProperty, SEND_DISTRICT_ID, SEND_BUILDING,
  enableSaveKioskDefaultConfiguration,
  disableSaveKioskDefaultConfiguration
} from "redux/install/Actions"

import {
  get,
  getState,
  post,
  payloadOnly,
  uiSnackMessageOnResponse,
  sortList,
  determineFailedLoginMessage,
  withErrorHandling
} from "redux/utils"

import {
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER,
  SYNC_RUN_STATUS_WAITING, URL_API_ROOT
} from "redux/constants";
import {
  INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR, INSTALL_STEP_SET_BUILDINGS, INSTALL_STEP_SET_DISTRICT,
  INSTALL_STEP_SET_SKYWARD_API_INFO,
  INSTALL_STEP_SYNCHRONIZE_BUILDINGS,
  INSTALL_STEP_SYNCHRONIZING_REASONS_ROOMS_STUDENTS_TYPES,
  INSTALL_STEP_RESET_DISTRICT,
} from "redux/install/constants";
import { 
  RETURN_TO_DISTRICT_SELECTION, 
  RETURN_TO_SET_SKYWARD_API_INFO, 
  RETURN_TO_SET_KIOSK_DEFAULT_CONFIGURATION, 
  RETURN_TO_SET_PASSWORD, 
  RETURN_TO_SET_LICENSING, 
  RETURN_TO_SET_ENTITIES,
  RETURN_TO_SET_BUILDINGS,
} from './Actions'

const uiDecoration = uiSnackMessageOnResponse(snackMessageShow)

let bgPollTask;

export function* bootApp() {
  yield put(bootPruneAppStateExcept('install'))
  const obj = yield get(`${URL_API_ROOT}/install/state`)
  if(obj.nextAction === 'default') {
    yield start(obj.payload)
  } else {
    yield nextActionOnError(obj)
  }
}

export function* loginSend ({email, password}) {
  const obj = yield post(
    `${URL_API_ROOT}/install/login`,
    {email,password}
  )
  if(!obj.success) obj.userMessage = determineFailedLoginMessage(obj)
  if(yield uiDecoration(obj)) {
    console.log(`appVersion: ${obj.payload.appVersion}`)
    yield start(obj.payload)
  }
}

export function* licenseSend() {
  const {staLicense, jsbTimezone, name, serverHostUrl, staLicenseEmail}  = yield getState('install.settings')
  yield put(settingsUpdateProperty({propertyName:'staLicenseControlsDisabled', val:true}))
  const obj = yield post(`${URL_API_ROOT}/install/set_license_key`, {
    jsbTimezone,
    name,
    serverHostUrl,
    staLicenseEmail,
    staLicense
  })
  if(yield uiDecoration(obj)) {

  } else {
    yield put(settingsUpdateProperty({propertyName:'staLicenseControlsDisabled', val:false}))
  }
}

export function* sendAdminPassword(payload) {
  const obj = yield post(`${URL_API_ROOT}/install/set_admin_password`, {
    password: payload.password
  })
  yield uiDecoration(obj)
}

export function* sendInstallComplete(payload) {
  yield put(stopInsallStatePolling())
  const obj = yield post(`${URL_API_ROOT}/install/set_install_complete`)
  if(yield uiDecoration(obj)) {
    if(obj.nextAction === 'goToAdminSettings') {
      window.location = '/mgmt/settings'
    }
  } else {
    yield nextActionOnError(obj)
  }
}

export function* sendKioskDefaultConfiguration() {
  const {kioskDefaultConfiguration} = yield getState('install.step3')
  yield put(disableSaveKioskDefaultConfiguration());
  const obj = yield post(`${URL_API_ROOT}/install/set_kiosk_default_configuration`, {
    kioskDefaultConfiguration
  })
  yield put(enableSaveKioskDefaultConfiguration());
  if(!(yield uiDecoration(obj))) {
      yield nextActionOnError(obj)
  }
}

export function* sendSkywardApiInfo() {
  const {skywardApiUrl, skywardClientId, skywardClientSecret} = yield getState('install.settings.temp')
  const obj = yield post(`${URL_API_ROOT}/install/set_skyward_api_info`, {
    skywardApiUrl,
    skywardClientId,
    skywardClientSecret
  })
  if(!(yield uiDecoration(obj))) {
      yield nextActionOnError(obj)
  }
}

export function* sendDistrictId() {
  const {vendorId} = yield getState('install.settings')
  const obj = yield post(`${URL_API_ROOT}/install/set_district`, {
    vendorId
  })
  if(!(yield uiDecoration(obj))) {
      yield nextActionOnError(obj)
  }
}

export function* sendBuilding() {
  const installData = yield getState('install')
  const stepData = installData[`step${INSTALL_STEP_SET_BUILDINGS}`]
  const buildingIds = stepData.buildings.reduce((acc, building) => {
    if(building.isEnabled) {
      acc.push(building.id)
    }
    return acc
  }, [])

  if(buildingIds.length === 0) {
    yield put(snackMessageError('You must select at least one building.'))
    return
  }

  const obj = yield post(`${URL_API_ROOT}/install/set_buildings`, {
    buildingIds
  })
  yield uiDecoration(obj)
}

export function* sendEntitySchoolYear() {
  const installData = yield getState('install')
  const {settings} = installData
  const stepData = installData[`step${INSTALL_STEP_SET_ENTITIES_SCHOOL_YEAR}`]
  const entityIds = stepData.entities.reduce((acc, entity) => {
    if(entity.isEnabled) {
      acc.push(entity.id)
    }
    return acc
  }, [])

  if(entityIds.length === 0) {
    yield put(snackMessageError('You must select at least one entity.'))
    return
  }

  const obj = yield post(`${URL_API_ROOT}/install/set_entities_school_year`, {
    entityIds,
    schoolYearId: settings.licensingJson.schoolYearId
  })
  yield uiDecoration(obj)
}


export function* start(obj) {
  yield put(loginSetUser(obj.user))
  yield put(stateUpdate(obj))
  yield put(goToStep(obj.currentStep))
  yield put(isReady())
  yield put(startInsallStatePolling())

}

export function* startInsStatePolling() {
  bgPollTask = yield fork(bgPoll, pollState, 1000)
  yield take(STOP_INSTALL_STATE_POLLING)
  yield cancel(bgPollTask)
}

/**
 * Important note - at first glance this polling process may seem overbuilt, but there are back-end processes (synchronization with Skyward)
 * that may take a lot of time due to sheer volume of data. We need to make sure the UI is pro-active and doesn't require pounding on the
 * refresh key to see where things are at.
 *
 * @returns {IterableIterator<CancelEffect | CancelEffect[] | *|IterableIterator<*|*|*>|Promise<any>|PutEffect<{payload, type}> | *>}
 */
export function* pollState() {
  const obj = yield get(`${URL_API_ROOT}/install/state`)
  if(yield uiDecoration(obj, false)) {
    yield put(stateUpdate(obj.payload))
    switch(obj.payload.currentStep) {
      case INSTALL_STEP_SET_SKYWARD_API_INFO :
        yield stepSkywardApiInfoMonitor(obj.payload)
        break
      case INSTALL_STEP_SET_DISTRICT :
        yield stepChooseDistrictThenSyncEntitiesAndYears(obj.payload)
        break
      case INSTALL_STEP_RESET_DISTRICT :
        yield stepSkywardApiInfoMonitor(obj.payload)
        break
      case INSTALL_STEP_SYNCHRONIZE_BUILDINGS :
        yield stepSyncBuildingsThenChoose(obj.payload)
        break
      case INSTALL_STEP_SYNCHRONIZING_REASONS_ROOMS_STUDENTS_TYPES :
        yield stepSyncReasonsRoomsStudentsTypes(obj.payload)
        break
      default :
    }
  } else {
    yield nextActionOnError(obj)
  }
}

function* nextActionOnError(obj, defaultMessage='There was an error communicating with the server. Please reload the page') {
  switch(obj.nextAction) {
    case 'showLogin' :
      yield put(loginShowUI(obj.payload))
      yield put(stopInsallStatePolling())
      return
    default :
      yield put(snackMessageError(defaultMessage, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
  }
}


let synchronizerNotRunningCounter = 0
let notConnectedToQCounter = 0
let connectedCounter = 0



export function* stepSkywardApiInfoMonitor(payload) {
  if(payload.skywardCredentials) {
    if(payload.jsbVendor.hasOwnProperty('Districts') && payload.jsbVendor.Districts.length > 0) {
      yield put(snackMessageSuccess("One moment.", DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
      const obj = yield post(`${URL_API_ROOT}/install/set_skyward_api_info_complete`)
      yield uiDecoration(obj)
    }
  }
}

export function* stepChooseDistrictThenSyncEntitiesAndYears(payload) {
  if(payload.skywardCredentials && payload.workerJobs && payload.settings.vendorId) {
    const workerJobStatus = workerJobStatusCompiler(payload.workerJobs)

    if(!payload.synchronizerRunning) {
      synchronizerNotRunningCounter++
      const message = `Synchronizer may not be running. Just a moment... ${synchronizerNotRunningCounter}`
      yield put(snackMessageError(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    } else if(!payload.skywardConnectionVerified) {
      synchronizerNotRunningCounter = 0
      notConnectedToQCounter++
      const message = [`Not connected to Skyward Q. Just a moment... ${notConnectedToQCounter}`].concat(workerJobStatus.displayMessage)
      yield put(snackMessageWarning(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    } else {
      synchronizerNotRunningCounter = 0
      notConnectedToQCounter = 0
      connectedCounter++
      const message = [`Connected to Skyward Q. Pulling data... ${connectedCounter++}`].concat(workerJobStatus.displayMessage)
      yield put(snackMessageSuccess(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    }

    if(workerJobStatus.complete) {
      yield delay(2000)
      yield put(snackMessageSuccess("One moment.", DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
      const obj = yield post(`${URL_API_ROOT}/install/set_district_complete`)
      yield uiDecoration(obj)
    }
  }
}

export function* stepSyncBuildingsThenChoose(payload) {
  if(payload.skywardCredentials) {
    const workerJobStatus = workerJobStatusCompiler(payload.workerJobs)

    if(!payload.synchronizerRunning) {
      synchronizerNotRunningCounter++
      const message = `Synchronizer may not be running. Just a moment... ${synchronizerNotRunningCounter}`
      yield put(snackMessageError(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    } else if(!payload.skywardConnectionVerified) {
      synchronizerNotRunningCounter = 0
      notConnectedToQCounter++
      const message = [`Not connected to Skyward Q. Just a moment... ${notConnectedToQCounter}`].concat(workerJobStatus.displayMessage)
      yield put(snackMessageWarning(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    } else {
      synchronizerNotRunningCounter = 0
      notConnectedToQCounter = 0
      connectedCounter++
      const message = [`Connected to Skyward Q. Pulling data... ${connectedCounter++}`].concat(workerJobStatus.displayMessage)
      yield put(snackMessageSuccess(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    }

    if(workerJobStatus.complete) {
      yield delay(2000)
      yield put(snackMessageSuccess("One moment.", DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
      const obj = yield post(`${URL_API_ROOT}/install/set_entities_school_year_complete`)
      yield uiDecoration(obj)
    }
  }
}

export function* stepSyncReasonsRoomsStudentsTypes(payload) {
  if(payload.skywardCredentials) {
    const workerJobStatus = workerJobStatusCompiler(payload.workerJobs)

    if(!payload.synchronizerRunning) {
      synchronizerNotRunningCounter++
      const message = `Synchronizer may not be running. Just a moment... ${synchronizerNotRunningCounter}`
      yield put(snackMessageError(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    } else if(!payload.skywardConnectionVerified) {
      synchronizerNotRunningCounter = 0
      notConnectedToQCounter++
      const message = [`Not connected to Skyward Q. Just a moment... ${notConnectedToQCounter}`].concat(workerJobStatus.displayMessage)
      yield put(snackMessageWarning(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    } else {
      synchronizerNotRunningCounter = 0
      notConnectedToQCounter = 0
      connectedCounter++
      const message = [`Connected to Skyward Q. Pulling data... ${connectedCounter++}`].concat(workerJobStatus.displayMessage)
      yield put(snackMessageSuccess(message, DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
    }

    if(workerJobStatus.complete) {
      yield delay(2000)
      yield put(snackMessageSuccess("One moment.", DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER))
      const obj = yield post(`${URL_API_ROOT}/install/set_buildings_complete`)
      yield uiDecoration(obj)
    }
  }
}

const workerJobStatusCompiler = (workerJobs) => {
  return sortList('displayName')(workerJobs).reduce((acc, workerJob) => {
    const jobStatus = {displayName:workerJob.displayName, summary:'Not started', complete:false}
    if(workerJob.uLastRunStart > 0) {  //this is how we know the job has started, and that it might have something to say.
      jobStatus.complete = workerJob.runStatus === SYNC_RUN_STATUS_WAITING
      jobStatus.summary = workerJob.runStatusSummary
    }
    acc.complete = (acc.complete && jobStatus.complete)
    acc.displayMessage.push(`${jobStatus.displayName}: ${jobStatus.summary}`)
    return acc
  }, {complete:true, displayMessage:[], jobStatuses:[]})
}



export function* bgPoll(pollingFn, delayMS=5000) {
  try {
    while (true) {
      yield call(pollingFn)
      yield delay(delayMS)
    }
  } finally {
    yield cancelled()
  }
}

export function* returnToDistrictSelection(payload) {
    const obj = yield post(`${URL_API_ROOT}/install/return_to_set_district`)
    yield uiDecoration(obj)
}

export function* returnToSetSkywardApiInfo(payload) {
    const obj = yield post(`${URL_API_ROOT}/install/return_to_set_skyward_api_info`)
    yield uiDecoration(obj)
}

export function* returnToSetKioskDefaultConfiguration(payload) {
    const obj = yield post(`${URL_API_ROOT}/install/return_to_set_kiosk_default_configuration`)
    yield uiDecoration(obj)
}

export function* returnToSetPassword(payload) {
  const obj = yield post(`${URL_API_ROOT}/install/return_to_set_password`)
  yield uiDecoration(obj)
}

export function* returnToSetLicensing(payload) {
  const obj = yield post(`${URL_API_ROOT}/install/return_to_set_licensing`)
  yield uiDecoration(obj)
}

export function* returnToSetEntities(payload) {
  const obj = yield post(`${URL_API_ROOT}/install/return_to_set_entities`)
  yield uiDecoration(obj)
}

export function* returnToSetBuildings(payload) {
  const obj = yield post(`${URL_API_ROOT}/install/return_to_set_buildings`)
  yield uiDecoration(obj)
}


export const watches = [
  takeEvery(BOOT_APP, withErrorHandling(bootApp)),
  takeEvery(LOGIN_SEND, payloadOnly(withErrorHandling(loginSend))),
  takeEvery(LICENSE_SEND, payloadOnly(withErrorHandling(licenseSend))),
  takeEvery(SEND_ADMIN_PASSWORD, payloadOnly(withErrorHandling(sendAdminPassword))),
  takeEvery(SEND_SKYWARD_API_INFO, payloadOnly(withErrorHandling(sendSkywardApiInfo))),
  takeEvery(SEND_DISTRICT_ID, payloadOnly(withErrorHandling(sendDistrictId))),
  takeEvery(SEND_KIOSK_DEFAULT_CONFIGURATION, payloadOnly(withErrorHandling(sendKioskDefaultConfiguration))),
  takeEvery(SEND_BUILDING, payloadOnly(withErrorHandling(sendBuilding))),
  takeEvery(SEND_ENTITY_SCHOOL_YEAR, payloadOnly(withErrorHandling(sendEntitySchoolYear))),
  takeEvery(SEND_INSTALL_COMPLETE, payloadOnly(withErrorHandling(sendInstallComplete))),
  takeEvery(START_INSTALL_STATE_POLLING, payloadOnly(withErrorHandling(startInsStatePolling))),
  takeEvery(RETURN_TO_DISTRICT_SELECTION, payloadOnly(withErrorHandling(returnToDistrictSelection))),
  takeEvery(RETURN_TO_SET_SKYWARD_API_INFO, payloadOnly(withErrorHandling(returnToSetSkywardApiInfo))),
  takeEvery(RETURN_TO_SET_KIOSK_DEFAULT_CONFIGURATION, payloadOnly(withErrorHandling(returnToSetKioskDefaultConfiguration))),
  takeEvery(RETURN_TO_SET_PASSWORD, payloadOnly(withErrorHandling(returnToSetPassword))),
  takeEvery(RETURN_TO_SET_LICENSING, payloadOnly(withErrorHandling(returnToSetLicensing))),
  takeEvery(RETURN_TO_SET_ENTITIES, payloadOnly(withErrorHandling(returnToSetEntities))),
  takeEvery(RETURN_TO_SET_BUILDINGS, payloadOnly(withErrorHandling(returnToSetBuildings))),
]

